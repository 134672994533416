import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Link, graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import SideBar from '../components/sidebar';
import PostsList from '../components/post_list';

const Tags = ({ pageContext, data }) => {
	const { tag } = pageContext;
	const { totalCount } = data.allMarkdownRemark;
	const tagHeader = `Post${totalCount === 1 ? '' : 's'} tagged with "${tag}"`;

	return (
		<Layout>
			<SEO description={`Tag: ${tag}`} title={`Tag: ${tag}`} />
			<SideBar />
			<div className="content posts-container">
				<h1 className="page-title">{tagHeader}</h1>
				{/* <hr className="hr-style" /> */}

				<PostsList data={data} />
				<Link to="/tags" className="bg-pastel text-white p-2 float-right">
					View All Tags
				</Link>
			</div>
		</Layout>
	);
};

Tags.propTypes = {
	pageContext: PropTypes.shape({
		tag: PropTypes.string.isRequired
	}),
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			totalCount: PropTypes.number.isRequired,
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired
						}),
						fields: PropTypes.shape({
							slug: PropTypes.string.isRequired
						})
					})
				}).isRequired
			)
		})
	})
};

export default Tags;

export const pageQuery = graphql`
	query($tag: String) {
		allMarkdownRemark(
			limit: 2000
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { tags: { in: [$tag] } } }
		) {
			totalCount
			edges {
				node {
					id
					frontmatter {
						title
						date(formatString: "DD MMM, YYYY")
						tags
					}
					excerpt
					fields {
						slug
					}
				}
			}
		}
	}
`;
